@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.section {
  background-color: $white;
}

.container {
  padding-top: $space-default;
  padding-bottom: $space-default;
}

.socialIcons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-default;
  padding-top: $space-default;

}

.redesIcon {
  width: 70px;
  height: 70px;
  color: $white;
  background-color: $primary;
  padding: $space-default;
  border-radius: $round-base;
  @include breakpoint-tablet() {
    width: 80px;
    height: 80px;
  }
}

.redesIconFb {
  @extend .redesIcon;
  padding: $space-default *1.3;
}

.containerSections {
  padding-top: $space-default *2;
  padding-bottom: $space-default *2;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 16px;
}

.containerSectionsAfiliate {
  background-color: $gray-light;
}

.input{
  border: 1px solid $gray;
	background-color: $white;
	color: $black;
	height: 44px;
  width: 100%;
	padding: $space-default;
	border-radius: $space-default / 2;
  text-align: center;
	&:focus-visible {
		outline: $gray-dark auto 1px;
	}
  @include breakpoint-tablet() {
    text-align: left;
    max-width: 350px;
  }
}
.textarea{
  @extend .input;
  height: auto;
}

.form{
  display: flex;
  flex-direction: column;
  gap: $space-default;
  padding: $space-default 0;
  width: 100%;
  max-width: 350px;
  button{
    margin-top: $space-default;
  }
}

.successMessage{
  text-align: center;
  padding: 16px;
  background-color: $success-bg;
  border-radius: $space-default;
  color: $success-font;
}