@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.card {
  min-width: 250px;
  max-width: 350px;
  padding: $space-default * 2;
  display: flex;
  flex-direction: column;
  gap: $space-default;
  color: $fblack;
  img {
    border-radius: $round-xs;
  }
  @include breakpoint-desktop() {
    width: calc(25% - $space-default);
    height: 100%;
  }
}

.featuredCard {
  @extend .card;
  background-color: $primary;
  border-radius: $round-sm;
  color: $white;
  box-shadow: $shadow-3;
  margin-bottom: $space-default * 2;
  @include breakpoint-desktop() {
    margin-bottom: 0;
    margin-right: $space-default * 2;
  }
}

.title{
  font-size: $text-lg;
  font-weight: bold;
}

.subTitle {
  display: flex;
  justify-content: flex-start;
  font-size: $text-xs;
  padding-top: $space-default / 4;
}

.description{
  font-size: $text-sm;
  text-align: left;
}

.image {
  position: relative;
  width: 100%;

}

.picture {
  width: 100%;
  height: 120px!important;
  display: block;
  overflow: hidden;
  border-radius: $space-default / 2;
}