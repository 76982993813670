@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.hero{
  height: calc(100vh - 101px);
  width: 100%;
  background-image: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
}

.heroImg {
  position: fixed;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  z-index: -1;
  margin-top: -101px;
}

.heroVideo{
  @extend .heroImg;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  transform: translateX(calc((100% - 100vw) / 2));
  @include breakpoint-desktop() {
    height: auto;
    bottom: initial;
  }
}

.heroContainer{
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
  padding-bottom: $space-default * 6;
}