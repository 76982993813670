@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.heading {
  font-weight: 700;
  color: $fblack;
  text-transform: uppercase;
  text-align: center;
  margin-top: $space-default * 3;
  @include breakpoint-desktop() {
    font-size: $text-3xl;
    margin-top: 0;
  }
}

.headingBox{
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

}

.back{
  position: absolute;
  left: 0px;
  top: 5px;
  display: flex;
  align-items: center;
  gap: calc($space-default / 2);
  color: $fblack;
}