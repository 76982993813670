@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.section {
  background-color: $white;
}

.container {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
  gap: $space-default;
  min-height: calc(100vh - 450px);
}

.cardButtonHorizontal {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: $space-default;
  margin: $space-default *4 0;
  @include breakpoint-tablet() {
    flex-direction: row;
    align-items:unset;
    justify-content: center;
  }
  @include breakpoint-desktop() {
    gap: $space-default *4;
  }
}