@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.cardLegis{
  box-shadow: $shadow-3;
  border-radius: $round-sm;
  margin: $space-default;
  overflow: hidden;
  width: 100%;
  @include breakpoint-desktop() {
    margin: $space-default $space-default $space-default 0;
    width: 28%;
  }
}

.cardLegisCarousel {
  @extend .cardLegis;
  @include breakpoint-desktop() {
    width: auto;
  }
}

.picture{
  width: 100%;
  height: 200px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  position: relative;
  overflow: hidden;
  @include breakpoint-tablet() {
    height: 240px;
  }
  @include breakpoint-desktop() {
    height: 240px;
  }
}


.info{
  width: 100%;
  margin: $space-default *2 0;
  padding: 0 $space-default;
  text-align: center;
}

.name {
  font-size: $text-2xl;
  font-weight: bold;
}

.cargo {
  color: $fblack;
  font-size: $text-lg;
}

.line{
  margin: $space-default auto;
  width: 30%;
  height: 2px;
  background-color: $primary;
}

.email {
  padding-top: $space-default;
  font-size: $text-base;
  color: $fblack;
}

.redes{
  margin: $space-default;
  display: flex;
  justify-content: center;
}

.icon{
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 0px 6px;
}

.redesIcon {
  width: 100%;
  height: 100%;
  color: $primary;
}

.redesIconFb {
  width: 80%;
  height: 70%;
  color: $primary;
}
