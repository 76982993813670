@use '../../styles/common/variables' as *;

.container{
  max-width: $max-width;
  margin-left: auto;
  margin-right: auto;
  padding: $space-default;
  padding-bottom: $space-default * 1.5;
}

.minHeight{
  min-height: calc(100vh - 450px);
}