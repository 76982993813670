@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.newsletterh4{
  font-size: $text-2xl;
  color: $white !important;
}
.newsletterh4Alt {
  font-size: $text-2xl;
  color: $fblack;
}

.input{
  border: 1px solid $gray;
	background-color: $white;
	color: $black;
	height: 44px;
  width: 100%;
	padding: $space-default;
	border-radius: $space-default / 2;
  text-align: center;
	&:focus-visible {
		outline: $gray-dark auto 1px;
	}
  @include breakpoint-tablet() {
    text-align: left;
    max-width: 350px;
  }
}

.inputerror {
  @extend .input;
  border: 2px solid $error!important;
  background-color: $error-outline;
}