@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.queDecimosCard {
  width: 100%;
  padding: $space-default*4 $space-default;
  background-color: $primary;
  border-radius: $round-sm;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-default / 2;
  position: relative;
  box-shadow: $shadow-1;
  @include breakpoint-tablet() {
    padding: $space-default*2;
    flex-direction: row;
    justify-content: space-between;
  }
}

.queDecimosCard:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid $primary;
  border-right: 12px solid transparent;
  border-top: 12px solid $primary;
  border-bottom: 20px solid transparent;
  left: 32px;
  bottom: -24px;
}

.queDecimosCardHeader{
  color: $white;
  font-weight: 700;
  text-align: center;
  font-size: $text-xl;
  padding-bottom: $space-default;
  @include breakpoint-tablet() {
    font-size: $text-2xl;
    text-align: left;
    padding-bottom: $space-default*2;
  }
}

.queDecimosCardCita{
  width: 90%;
  @include breakpoint-tablet() {
    width: 60%;
  }
}

.queDecimosImg{
  border-radius: 50%;
  width: 75px;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  @include breakpoint-tablet() {
    width: 350px;
    height: 350px;
  }
}

.queDecimosAuthor{
  color: $white;
  font-weight: 700;
  @include breakpoint-tablet() {
    display: none;
  }
}

.queDecimosAuthorWeb{
  display: none;
  @include breakpoint-tablet() {
    color: $black;
    font-weight: 700;
    font-size: $text-xl;
    padding-bottom: $space-default;
    display: block;
  }
}

.queDecimosBtn{
  display: none;
  @include breakpoint-tablet() {
    display: inline-block;
  }
}

.xs{
  margin: $space-default 0;
  h4{
    @include breakpoint-tablet() {
      font-size: $text-xl;
    }
  }
  h3 {
    @include breakpoint-tablet() {
      font-size: $text-2xl;
    }
  }
  .queDecimosImg{
    @include breakpoint-tablet() {
      width: 250px;
      height: 250px;
    }
  }
}