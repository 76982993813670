@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.queDecimosSection {
  padding: $space-default*2  0;
  background-color: $white;
}

.queDecimosContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-default*2;
}

.newsRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-default;
  @include breakpoint-tablet() {
    flex-direction: row;
    padding-bottom: $space-default*2;
  }
}
