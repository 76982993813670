@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.card {
  width: 100%;
  padding: $space-default * 2;
  display: flex;
  flex-direction: column;
  gap: $space-default;
  color: $fblack;
  img {
    border-radius: $round-xs;
  }
  @include breakpoint-tablet() {
    height: 100%;
    flex-direction: row;
  }
}

.featuredCard {
  @extend .card;
  background-color: $primary;
  border-radius: $round-sm;
  color: $white;
  box-shadow: $shadow-3;
  margin: $space-default 0 $space-default * 2;
}
.cardText{
  padding: 0 $space-default;
}
.cardText, .cardImg{
  flex-basis: 50%;
}

.title{
  font-size: $text-lg;
  font-weight: bold;
  @include breakpoint-tablet() {
    font-size: $text-2xl;
  }
  @include breakpoint-desktop() {
    font-size: $text-3xl;
  }
}

.subTitle {
  display: flex;
  justify-content: space-between;
  font-size: $text-xs;
  padding-top: $space-default / 4;
  @include breakpoint-tablet() {
    font-size: $text-base;
  }
  @include breakpoint-desktop() {
    font-size: $text-lg;
  }
}

.description{
  font-size: $text-sm;
  padding-top: $space-default *2;
  text-align: left;
  @include breakpoint-tablet() {
    font-size: $text-base;
  }
  @include breakpoint-desktop() {
    font-size: $text-lg;
  }
}
