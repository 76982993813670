
// COLORS

// MATERIAL DESIGN RED
$red-50:           rgba(253, 224, 220, 1.0);    // #fde0dc
$red-100:          rgba(249, 189, 187, 1.0);    // #f9bdbb
$red-200:          rgba(246, 153, 136, 1.0);    // #f69988
$red-300:          rgba(243, 108, 96, 1.0);     // #f36c60
$red-400:          rgba(232, 78, 64, 1.0);      // #e84e40
$red-500:          #E53935;
$red-600:          rgba(221, 25, 29, 1.0);      // #dd191d
$red-700:          rgba(208, 23, 22, 1.0);      // #d01716
$red-800:          rgba(196, 20, 17, 1.0);      // #c41411
$red-900:          rgba(176, 18, 10, 1.0);      // #b0120a
$red-a100:         rgba(255, 121, 151, 1.0);    // #ff7997
$red-a200:         rgba(255, 81, 119, 1.0);     // #ff5177
$red-a400:         rgba(255, 45, 111, 1.0);     // #ff2d6f
$red-a700:         rgba(224, 0, 50, 1.0);       // #e00032



$white: #fff;
$fwhite: #fafafa;

$black: #000;
$fblack: #333;
$black-lighter: #555;
$error: #F44336;
$error-outline: #E57373;

$line: #EFF1F5;
$gray: #D3D3D3;
$gray-light: #EEEEEE;
$gray-dark: #999;

$success-bg: rgb(237, 247, 237);
$success-font: rgb(30, 70, 32);

// END COLORS

// SETTINGS

$primary: $red-500;
$primary-light: $red-400;
$primary-dark: $red-800;

$whatsapp: #25d366;
$whatsappDark: #128c7e;

$space-default: 16px;
$max-width: 1200px;

$text-xs:	0.75rem; /* 12px */
$text-sm: 0.875rem; /* 14px */
$text-base: 1rem; /* 16px */
$text-lg: 1.125rem; /* 18px */
$text-xl: 1.25rem; /* 20px */
$text-2xl: 1.5rem; /* 24px */
$text-3xl: 1.875rem; /* 30px */
$text-4xl: 2.25rem; /* 36px */
$text-5xl: 3rem; /* 48px */
$text-6xl: 3.75rem; /* 60px */
$text-7xl: 4.5rem; /* 72px */
$text-8xl: 6rem; /* 96px */
$text-9xl: 8rem; /* 128px */

$round-xs:	0.5rem; /* 8px */
$round-sm: 0.75rem; /* 12px */
$round-base: 1rem; /* 16px */

$outline: $red-300;

// END SETTINGS

//shadows
$shadow-1: 0 1px 1px rgba(0, 0, 0, 0.1), 0 1px 1px rgba(0, 0, 0, 0.15);
$shadow-2: 0 2px 5px rgba(0, 0, 0, 0.14), 0 2px 5px rgba(0, 0, 0, 0.2);
$shadow-3: 0 3px 6px rgba(0, 0, 0, 0.1), 0 3px 6px rgba(0, 0, 0, 0.15);

//animations
$animation-1: all 0.2s cubic-bezier(0.26, 0.8, 0.26, 1);

@keyframes pulse {
  0% {
    background-color: $fwhite;
  }
  50%{
    background-color: $line;
  }
  100% {
    background-color: $fwhite;
  }
}