@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.heading {
  font-weight: 700;
  font-size: $text-base;
  color: $fblack;
  text-align: center;
  @include breakpoint-desktop() {
    font-size: $text-xl;
  }
}

