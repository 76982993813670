@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;
.sitiosDeInteres{
  background-color: $primary;
  padding: $space-default * 2  0;
  display: flex;
  @include breakpoint-desktop() {
    padding: $space-default * 2  0;
  }
}

.sitio{
  width: 150px;
  filter: grayscale(100%);
}

.list{
  display: flex;
  flex-direction: column;
  gap: $space-default;
  @include breakpoint-tablet() {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: calc($space-default*3);

  }
}