@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.section {
  background-color: $white;
}

.container {
  padding-top: $space-default *2;
  padding-bottom: $space-default *2;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 16px;
}
.content {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: 16px;
  p {
    font-size: $text-lg;
    max-width: 900px;
    color: $fblack;
  }
  h2 {
    font-weight: 700;
    color: $fblack;
    text-align: center;
    @include breakpoint-desktop() {
      font-size: $text-3xl;
    }
  }
}

.socialIcons{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: $space-default;
  padding-top: $space-default *4;
  padding-bottom: $space-default *3;
  flex-wrap: wrap;
  @include breakpoint-tablet() {
    gap: $space-default *2;
  }
}

.redesIcon {
  width: 70px;
  height: 70px;
  color: $white;
  background-color: $primary;
  border-radius: $round-base;
  @include breakpoint-tablet() {
    width: 120px;
    height: 120px;
  }
}
.iconLink{
  display: block;
  position: relative;
  width: 70px;
  height: 70px;
  @include breakpoint-tablet() {
    width: 120px;
    height: 120px;
  }
}

.iconPicture{
  position: absolute;
  display: block;
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  @include breakpoint-tablet() {
    width: 120px;
    height: 120px;
  }
}

.redesIconFb {
  @extend .redesIcon;
  padding: $space-default *1.3;
}

.containerSectionsAfiliate {
  background-color: $gray-light;
}

.input{
  border: 1px solid $gray;
	background-color: $white;
	color: $black;
	height: 44px;
  width: 100%;
	padding: $space-default;
	border-radius: calc($space-default / 2);
  text-align: center;
	&:focus-visible {
		outline: $gray-dark auto 1px;
	}
  @include breakpoint-tablet() {
    text-align: left;
    max-width: 350px;
  }
}

.form{
  display: flex;
  flex-direction: column;
  gap: $space-default;
  padding: $space-default 0;
  width: 100%;
  max-width: 350px;
  button{
    margin-top: $space-default;
  }
}