@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.socialistasHaciendoSection {
  padding: $space-default*2  0;
  background-color: $gray-light;
}

.socialistasHaciendoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-default *2;
  @include breakpoint-tablet() {
    min-height: 50vh;
    justify-content: center;
  }
}

.socialistasHaciendoRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  gap: $space-default;
}

.cardLogos{
  background-color: $primary;
  border-radius: $round-sm;
  height: 40vw;
  min-height: 155px;
  max-height: 200px;
  width: 40vw;
  min-width: 155px;
  max-width: 200px;
  position: relative;
  text-align: center;
  box-shadow: $shadow-1;
}

.imglogo {
  width: 120px;
  height: 120px;
  position: absolute;
  display: block;
  top: 50%;  
  left: 50%; 
  transform: translate(-50%, -50%);
}