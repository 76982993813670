@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.search {
  width:100%;
  height: calc(100vh - 101px);
  background-color: $primary;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
  padding-bottom: 100px;
}

.visible{
  display: flex;
}

.input { 
  font-size: 22px;
  margin: $space-default 0;
  background-color: transparent;
  color: $white;
  max-width: 100%;
  border: 0;
  outline: none;
  border-bottom: 3px solid $fwhite;
  border-radius: $round-xs;
  padding: $space-default;
  @include breakpoint-tablet() {
    font-size: 50px;
    margin: $space-default*3 0;
  }
}

.input::placeholder {
  opacity: 0.5;
  color: $fwhite;
}