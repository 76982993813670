@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.newsContainer{
  max-width: 900px;
  padding: $space-default *2 $space-default;
  @include breakpoint-tablet() {
    padding: $space-default *2 0;
  }
}

.moreNewsContainer{
  padding: $space-default *2 0;
}

.title{
  font-size: $text-lg;
  font-weight: bold;
  @include breakpoint-tablet() {
    font-size: $text-2xl;
  }
  @include breakpoint-desktop() {
    font-size: $text-4xl;
  }
}

.cat {
  color: $red-500;
}

.subTitle {
  display: flex;
  justify-content: space-between;
  font-size: $text-xs;
  padding-top: $space-default;
  @include breakpoint-tablet() {
    font-size: $text-base;
  }
  @include breakpoint-desktop() {
    font-size: $text-lg;
  }
}

.header{
  padding: $space-default *2 0;
}

.categorias {
  display: none;
  @include breakpoint-tablet() {
    display: flex;
    gap: $space-default;
  }
}

.content{
  p {
    padding-bottom: $space-default * 2;
    line-height: $space-default *1.7;
    color: $black-lighter;
    font-size: $text-lg;
    text-align: left;
  }
  ul {
    margin: $space-default 0 0; 
    max-width: 100%;
    display: flex;
    flex-direction: column;
    list-style: none;
    gap: $space-default;
    @include breakpoint-tablet() {
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
  li {
    width: 100%;
    display: block;
    @include breakpoint-tablet() {
      width: 32%;
    }
    img{ 
      width: 100%;
      max-width: 100%;
      height: auto;
    }
  }
}

iframe {
  max-width: 100%;
}