@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.section {
  background-color: $white;
}

.container {
  padding-top: $space-default *2;
  padding-bottom: $space-default *2;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: $space-default;
}

.subheader{
  margin: $space-default *2 0 0;
}

.list{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: calc((1168px - 200px * 5) / 4);
  width: 100%;
  padding: $space-default * 2 0;
  @include breakpoint-tablet() {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}