@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.carousel {
  overflow: hidden;
  width: 100%;
  max-width: 350px;
  @include breakpoint-tablet() {
    max-width: 800px;
  }
  @include breakpoint-desktop() {
    max-width: 100vw;
  }
}

.nextArrow{
  right: 10px;
  z-index: 999;
}

.prevArrow{
  left: 10px;
  z-index: 999;
}