@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.section {
  background-color: $white;
}

.container {
  padding-top: $space-default *2;
  padding-bottom: $space-default *2;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: $space-default;
  min-height: calc(100vh - 422px);
}

.subheader{
  margin: $space-default *2 0 0;
}

.list{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: calc((1168px - 200px * 5) / 4);
  width: 100%;
  padding: $space-default * 2 0;
  @include breakpoint-tablet() {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

.listLarge{
  @extend .list;
  gap: calc((1168px - 270px * 4) / 3);
}

.file {
  width: 270px;
  height: 183px;
  background-color: $gray-light;
  padding: $space-default * 2;
  border-radius: $round-base;
  border: 1px solid $line;
  box-shadow: $shadow-1;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.destacado{
  @extend .file;
  background-color: $primary;
  border: 1px solid $primary-dark;
}

.title{
  font-size: $text-base;
  font-weight: bold;
  color: $fblack;
  margin-bottom: $space-default;
}

.titleWhite{
  @extend .title;
  color: $white;
}