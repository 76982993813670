@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.cardButtonHorizontal{
  width: 100%;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  padding: $space-default;
  align-items: center;
  border-radius: $round-sm;
  box-shadow: $shadow-2;
  max-width: 250px;
  @include breakpoint-tablet() {
    flex-direction: column-reverse;
    padding: $space-default *4;
    justify-content: center;
    gap: $space-default;
  }
  h4{
    color: $white;
    font-size: $text-lg;
    font-weight: 700;
    @include breakpoint-tablet() {
      text-align: center;
    }
  }
}

.picture{
  display: block;
  position: relative;
  width: 50px;
  height: 50px;
}