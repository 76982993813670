@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.section {
  background-color: $white;
}

.container {
  padding-top: $space-default *2;
  padding-bottom: $space-default *2;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  gap: $space-default;
}

.cardButtonHorizontal {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: $space-default;
  margin: $space-default *4 0;
  @include breakpoint-tablet() {
    flex-direction: row;
    align-items:unset;
    justify-content: center;
  }
  @include breakpoint-desktop() {
    gap: $space-default *4;
  }
}

.picture{
  width: 100px;
  height: 50px;
}