html,
body {
	padding: 0;
	margin: 0;
	font-family: 'Roboto', sans-serif;
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

ul,
li,
figure {
	margin: 0;
	padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
	margin: 0;
	font-weight: normal;
}

.wp-video,.wp-video video  {
	width: 100% !important;
	aspect-ratio: 16 / 9 !important;
	height: auto !important;
}

