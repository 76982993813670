@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;


.default{
  padding-top: $space-default;
  padding-bottom: $space-default;
}

.x2 {
  padding-top: $space-default *2;
  padding-bottom: $space-default *2;
}

.whatsapp {
  color: $white;
  background-color: $whatsapp;
  border-radius: 100%;
  position: fixed;
  bottom: $space-default;
  right: $space-default;
  z-index: 999999999;
  width: 60px;
  height: 60px;
  transition: background-color 0.5s ease; 
  &:hover{
    background-color: $whatsappDark;
  }
  svg{
    width: 40px;
    height: 40px;
  }
}