@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.newsletterSection {
  padding: $space-default*2  0;
  background-color: $primary-light;
}

.newsletterContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-default *2;
  @include breakpoint-tablet() {
    min-height: 50vh;
    justify-content: center;
  }
}

.newsletterRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: $space-default;
}

.newsletterh4{
  font-size: $text-2xl;
  color: $white;
}

.input{
  border: 0px;
	background-color: $white;
	color: $black;
	height: 44px;
  width: 100%;
  max-width: 250px;
	padding: $space-default;
	border-radius: $space-default / 2;
	&:focus-visible {
		outline: $outline auto 1px;
	}
  @include breakpoint-tablet() {
    max-width: 350px;
  }
}
.inputerror {
  @extend .input;
  border: 2px solid $error!important;
  background-color: $error-outline;
}