@mixin breakpoint-tablet {
	@media (min-width: 768px) {
		@content;
	}
}

@mixin breakpoint-desktop {
	@media (min-width: 1200px) {
		@content;
	}
}

@mixin breakpoint-onlyMobile {
	@media (max-width: 768px) {
		@content;
	}
}