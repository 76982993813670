
@use '../../styles/common/variables' as *;

.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon{
  font-size: 150px;
  color: $primary;
  margin-bottom: calc($space-default * 2);
}

.h2{
  color: $fblack;
}