@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;


.footer {
  background-color: $primary-dark;
  padding: $space-default * 2  0;
  display: flex;
  @include breakpoint-desktop() {
    padding: $space-default 0;
  }
}

.container {
  display: flex;
  gap: $space-default * 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  color: $gray;
  @include breakpoint-desktop() {
    gap: $space-default;
    flex-direction: row-reverse;
    justify-content: space-between;
  }
}

.socialIcons{
  display: flex;
  align-items: center;
  justify-content: center;
  a{
    padding: 8px;
  }
}

.redesIcon {
  width: 100%;
  height: 100%;
  color: $white;
}

.redesIconFb {
  width: 80%;
  height: 70%;
  color: $white;
}

.footerLinks{
  display: flex;
  gap: $space-default;
  @include breakpoint-desktop() {
    flex-direction: column;
    margin-top: $space-default;
  }
  nav {
    display: flex;
    flex-direction: column;
    gap: $space-default;
    @include breakpoint-desktop() {
      flex-direction: row;
      justify-content: center;
    }
    a {
      display: block;
      color: $white;
      font-size: $text-sm;
      @include breakpoint-desktop() {
        font-size: $text-base;
        font-weight: 500;
      }
    }
  }
}

.leftCol{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: $space-default;
}

.rightCol{
  width: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: calc($space-default/2);
  @include breakpoint-desktop() {
    width: 80%;
    text-align: left;
  }
}