@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;


.header {
  background-color: $primary;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.menuIcon {
  width: 100%;
  height: 100%;
  color: $white;

}

.menu{
  @include breakpoint-desktop() {
    display: none;
  }
}
.nav {
  width:100%;
  height: calc(100vh - 101px);
  background-color: $primary;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: none;
  a {
    color: $white;
    padding: $space-default;
    font-size: $text-xl;
    font-weight: bold;
    text-transform: uppercase;
  }
}

.navDesktop {
  @extend .nav;
  @include breakpoint-desktop() {
    display: flex;
    flex-direction: row;
    width: auto;
    height: auto;
    gap: $space-default;
    margin-left: auto;
    margin-right: $space-default;
    a {
      font-size: $text-base;
      padding: 0;
      text-align: center;
    }
  }
}

.nav.visible{
  display: flex;
}
.searchButton{
  order: -1;
  color: $white;
  font-size: $text-2xl;
  @include breakpoint-desktop() {
    order: 0;
  }
}