@use '../../styles/common/variables' as *;

.loading {
  width: 100%;
  height: 100%;
  background-color: $primary;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
}
.skeleton {
  color: hsl(200, 20%, 95%);
  animation: skeleton-loading 1s linear infinite alternate;
  svg {
    fill: currentColor;
    width: 200px;
  }
}

@keyframes skeleton-loading {
  0% {
    color: hsl(200, 20%, 95%);
  }
  100% {
    color: hsl(0, 0%, 65%);
  }
}