@use './variables' as *;
@use './mixins' as *;

.heroHeadings {
  color: $white;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: $text-5xl;
  @include breakpoint-tablet() {
    font-size: $text-6xl;
  }
}

.heroText{
  color: $white;
  text-align: center;
  font-size: $text-lg;
  padding-top: $space-default;
  @include breakpoint-tablet() {
    font-size: $text-2xl;
    padding: 0 $space-default * 4;
    padding-top: $space-default;
  }
}

.wp-block-gallery{
  list-style: none;
  display: flex;
  padding: $space-default;
  gap: $space-default;
  flex-wrap: wrap;
}

.blocks-gallery-item{
  width: calc(100% / 3 - $space-default);
  figure, img{
    width: 100%;
    max-width: 100%;
    border-radius: $space-default / 2;
  }
}