@use '../../styles/common/variables' as *;

.base {
	width: auto;
	padding: 0.5rem 2rem;
	border-radius: $round-xs;
	border: 1px solid $primary;
	color: $white;
	background-color: $primary;
	height: 44px;
	font-size: $text-base;
	font-weight: bold;
	cursor: pointer;
	transition: $animation-1;
	display: flex;
	justify-content: center;
	align-items: center;
	&:hover {
		background-color: $primary-light;
		border: 1px solid $primary-light;
		color: $white;
		box-shadow: $shadow-3;
	}
	&:active {
		border: 1px solid $primary-dark;
		background-color: $primary-dark;
		box-shadow: $shadow-1;
	}
}

.buttonDefault {
	@extend .base;
	background-color: $primary;
}

.buttonDefaultAlt{
	@extend .base;
	background-color: $fblack;
	border: 1px solid $fblack;
	color: $white;
	border-radius: 8px;
	&:hover {
		background-color: #555;
		border: 1px solid #555;
		color: $white;
		box-shadow: $shadow-3;
	}
	&:active {
		border: 1px solid $black;
		background-color: $black;
		box-shadow: $shadow-1;
	}
}

.buttonDisabled {
	@extend .base;
	background-color: $gray;
	pointer-events: none;
  border: $gray;
	&:hover {
		background-color: $gray;
		border: 1px solid $gray;
		color: $white;
	}
	&:active {
		border: 1px solid $gray;
		background-color: $gray;
	}
}

.none {
	background-color: transparent;
	border-color: transparent;
}

.buttonIcon {
	@extend .none;
	width: 44px;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.buttonDefaultOutlineAlt {
	@extend .base;
	width: auto;
	min-width: 100px;
	font-size: 0.7rem;
	color: $primary;
	border: 2px solid $primary;
	background-color: transparent;
	border-radius: 0.5rem;
	padding: 0 0.8rem;
	margin-right: 0.5rem;
	&:hover {
		background-color: $primary;
		border: 1px solid $primary;
		color: $white;
	}
	&:active {
		border: 1px solid $primary;
		background-color: $primary;
		color: $white;
	}
}