@use '../../styles/common/variables' as *;
@use '../../styles/common/mixins' as *;

.newsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-default *2;
}

.newsRow {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-default;
  @include breakpoint-tablet() {
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    margin-bottom: $space-default*2;
    flex-wrap: wrap;
  }
  @include breakpoint-desktop() {
    flex-wrap: nowrap;
  }
  @include breakpoint-onlyMobile(){
    height: auto!important;
  }
}

.category{
  font-weight: 700;
  font-size: $text-base;
  color: $fblack;
  text-align: left;
  align-self: flex-start;
  text-transform: uppercase;
  @include breakpoint-desktop() {
    font-size: $text-xl;
  }
}